import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { QuestionFieldsFragmentDoc } from '../../../../shared/custom-fields/generated/cfQuestionFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentCartedCustomFieldsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ParentCartedCustomFieldsQuery = {
  student?:
    | {
        id: string;
        name: string;
        questions?:
          | Array<
              | {
                  id: string;
                  ownerId?: string | null | undefined;
                  ownerType?: Types.CfOwnerKind | null | undefined;
                  kind?: Types.CfKind | null | undefined;
                  title?: string | null | undefined;
                  description?: string | null | undefined;
                  choices?: Array<string> | null | undefined;
                  stage?: Types.CfStage | null | undefined;
                  required?: boolean | null | undefined;
                  owner?: { name?: string | null | undefined } | { name: string } | { name: string } | null | undefined;
                  files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        answers?: Array<{ ownerId?: string | null | undefined; ownerType?: Types.CfOwnerKind | null | undefined; answers?: { [key: string]: any } | null | undefined } | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export const ParentCartedCustomFieldsDocument = gql`
  query ParentCartedCustomFields($id: ID!) @urql(additionalTypenames: ["Student"]) {
    student(student: $id) {
      id
      name
      questions: cfQuestionsCarted {
        ...questionFields
      }
      answers: cfAnswersCarted {
        ownerId
        ownerType
        answers
      }
    }
  }
  ${QuestionFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentCartedCustomFieldsArgs = MakeOptional<UseQueryArgs<ParentCartedCustomFieldsQueryVariables, ParentCartedCustomFieldsQuery>, 'query'>;

export function useParentCartedCustomFieldsQuery(options: ParentCartedCustomFieldsArgs = {}) {
  return useQuery<ParentCartedCustomFieldsQueryVariables, ParentCartedCustomFieldsQuery>({ query: ParentCartedCustomFieldsDocument, ...options });
}

export type ParentCartedCustomFieldsOptions = ExecQueryOptions<ParentCartedCustomFieldsQueryVariables>;

export function parentCartedCustomFields(options: ParentCartedCustomFieldsOptions) {
  return executeQuery<ParentCartedCustomFieldsQuery, ParentCartedCustomFieldsQueryVariables>(ParentCartedCustomFieldsDocument, options);
}

export type ParentCartedCustomFieldsSelections = ParentCartedCustomFieldsQuery['student'];

(ParentCartedCustomFieldsDocument as any).additionalTypenames = ['Student'];
