import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentScheduleFieldsFragmentDoc } from './studentScheduleFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentScheduleQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentScheduleQuery = {
  user?:
    | {
        id: string;
        personalCalendar?: string | null | undefined;
        students?:
          | Array<{
              id: string;
              name: string;
              enrollments?:
                | Array<
                    | {
                        id: string;
                        status: Types.EnrollmentStatusEnum;
                        waitlistKind: Types.WaitlistKind;
                        pickup?: string | null | undefined;
                        dismissal?: string | null | undefined;
                        groups?: Array<string> | null | undefined;
                        rosterPeriod?: string | null | undefined;
                        pickupDropoffRequired: boolean;
                        startDate?: string | null | undefined;
                        endDate?: string | null | undefined;
                        isChildEnrollment: boolean;
                        priceConfig?:
                          | { kind: Types.PriceConfigKind }
                          | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string> }
                          | { kind: Types.PriceConfigKind; weekdays: Array<string> }
                          | { kind: Types.PriceConfigKind }
                          | null
                          | undefined;
                        parentEnrollment?: { id: string; course: { id: string; startDate?: string | null | undefined } } | null | undefined;
                        course: {
                          id: string;
                          name?: string | null | undefined;
                          room?: string | null | undefined;
                          siteGradeLabels?: Array<string | null | undefined> | null | undefined;
                          ageGroup?: string | null | undefined;
                          status: Types.CourseStatus;
                          kind?: Types.CourseKind | null | undefined;
                          noEnrichmentDays: Array<string | null | undefined>;
                          startDate?: string | null | undefined;
                          endDate?: string | null | undefined;
                          courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
                          company?: { name?: string | null | undefined; id: string } | null | undefined;
                          site: { id: string; name?: string | null | undefined; checkoutDestinations?: Array<string> | null | undefined; pickupDestinations?: Array<string> | null | undefined };
                          season: { id: string; name: string };
                          homeTeam?: { name?: string | null | undefined } | null | undefined;
                          awayTeam?: { name?: string | null | undefined } | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ParentScheduleDocument = gql`
  query ParentSchedule {
    user: currentUser {
      id
      personalCalendar
      students {
        ...studentScheduleFields
      }
    }
  }
  ${StudentScheduleFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentScheduleArgs = MakeOptional<UseQueryArgs<ParentScheduleQueryVariables, ParentScheduleQuery>, 'query'>;

export function useParentScheduleQuery(options: ParentScheduleArgs = {}) {
  return useQuery<ParentScheduleQueryVariables, ParentScheduleQuery>({ query: ParentScheduleDocument, ...options });
}

export type ParentScheduleOptions = ExecQueryOptions<ParentScheduleQueryVariables>;

export function parentSchedule(options: ParentScheduleOptions) {
  return executeQuery<ParentScheduleQuery, ParentScheduleQueryVariables>(ParentScheduleDocument, options);
}

export type ParentScheduleSelections = ParentScheduleQuery['user'];
