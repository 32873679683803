import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyVendorsQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyVendorsQuery = {
  company?:
    | {
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
        vendors?:
          | Array<{
              id: string;
              inCompliance: boolean;
              site: { id: string; name?: string | null | undefined; usingCompliance: boolean; contact?: { email?: string | null | undefined } | null | undefined; policy?: { token: string } | null | undefined };
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CompanyVendorsDocument = gql`
  query CompanyVendors($company: ID!) {
    company(company: $company) {
      ...commonCompany
      vendors {
        id
        inCompliance
        site {
          id
          name
          contact {
            email
          }
          usingCompliance
          policy {
            token
          }
        }
      }
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyVendorsArgs = MakeOptional<UseQueryArgs<CompanyVendorsQueryVariables, CompanyVendorsQuery>, 'query'>;

export function useCompanyVendorsQuery(options: CompanyVendorsArgs = {}) {
  return useQuery<CompanyVendorsQueryVariables, CompanyVendorsQuery>({ query: CompanyVendorsDocument, ...options });
}

export type CompanyVendorsOptions = ExecQueryOptions<CompanyVendorsQueryVariables>;

export function companyVendors(options: CompanyVendorsOptions) {
  return executeQuery<CompanyVendorsQuery, CompanyVendorsQueryVariables>(CompanyVendorsDocument, options);
}

export type CompanyVendorsSelections = CompanyVendorsQuery['company'];
