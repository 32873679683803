import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CurrentUserQueryVariables = Types.Exact<{
  site?: Types.InputMaybe<Types.Scalars['ID']>;
  company?: Types.InputMaybe<Types.Scalars['ID']>;
  course?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type CurrentUserQuery = {
  currentUser?:
    | {
        id: string;
        deactivated?: boolean | null | undefined;
        uuid?: string | null | undefined;
        firstName: string;
        lastName: string;
        name?: string | null | undefined;
        email: string;
        phone?: string | null | undefined;
        tosAccepted?: boolean | null | undefined;
        roles?:
          | {
              admin: boolean;
              parent: boolean;
              organizer?: Types.OrganizerRole | null | undefined;
              course?: Types.CourseRole | null | undefined;
              provider?: Types.CompanyRole | null | undefined;
              site?: { id: string; name?: string | null | undefined; userSiteRole?: Types.MemberRole | null | undefined; userCompanyRole?: Types.MemberRole | null | undefined } | null | undefined;
              company?: { id: string; name?: string | null | undefined; email?: string | null | undefined; quickStartGuide?: { hidden?: boolean | null | undefined } | null | undefined } | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CurrentUserDocument = gql`
  query CurrentUser($site: ID, $company: ID, $course: ID) {
    currentUser {
      id
      deactivated
      uuid
      firstName
      lastName
      name
      email
      phone
      tosAccepted
      roles(site: $site, company: $company, course: $course) {
        admin
        parent
        organizer
        course
        site {
          id
          name
          userSiteRole
          userCompanyRole
        }
        provider
        company {
          id
          name
          email
          quickStartGuide {
            hidden
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CurrentUserArgs = MakeOptional<UseQueryArgs<CurrentUserQueryVariables, CurrentUserQuery>, 'query'>;

export function useCurrentUserQuery(options: CurrentUserArgs = {}) {
  return useQuery<CurrentUserQueryVariables, CurrentUserQuery>({ query: CurrentUserDocument, ...options });
}

export type CurrentUserOptions = ExecQueryOptions<CurrentUserQueryVariables>;

export function currentUser(options: CurrentUserOptions) {
  return executeQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}

export type CurrentUserSelections = CurrentUserQuery['currentUser'];
