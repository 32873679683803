import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentPaymentMethodsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentPaymentMethodsQuery = {
  user?:
    | {
        creditCard?: { id: string; brand: Types.CardBrand; expiration: string; last4: string; name?: string | null | undefined } | null | undefined;
        usBankAccount?: { fingerprint: string; last4: string; bankName: string; routingNumber: string } | null | undefined;
      }
    | null
    | undefined;
};

export const ParentPaymentMethodsDocument = gql`
  query ParentPaymentMethods {
    user: currentUser {
      creditCard {
        id
        brand
        expiration
        last4
        name
      }
      usBankAccount {
        fingerprint
        last4
        bankName
        routingNumber
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentPaymentMethodsArgs = MakeOptional<UseQueryArgs<ParentPaymentMethodsQueryVariables, ParentPaymentMethodsQuery>, 'query'>;

export function useParentPaymentMethodsQuery(options: ParentPaymentMethodsArgs = {}) {
  return useQuery<ParentPaymentMethodsQueryVariables, ParentPaymentMethodsQuery>({ query: ParentPaymentMethodsDocument, ...options });
}

export type ParentPaymentMethodsOptions = ExecQueryOptions<ParentPaymentMethodsQueryVariables>;

export function parentPaymentMethods(options: ParentPaymentMethodsOptions) {
  return executeQuery<ParentPaymentMethodsQuery, ParentPaymentMethodsQueryVariables>(ParentPaymentMethodsDocument, options);
}

export type ParentPaymentMethodsSelections = ParentPaymentMethodsQuery['user'];
