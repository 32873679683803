import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CourseCardFieldsFragmentDoc } from '../../course/course-card/generated/coursesCardFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CourseOptionsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type CourseOptionsQuery = {
  course?:
    | {
        id: string;
        name?: string | null | undefined;
        status: Types.CourseStatus;
        kind?: Types.CourseKind | null | undefined;
        grades?: Array<string | null | undefined> | null | undefined;
        ageMin?: number | null | undefined;
        ageMax?: number | null | undefined;
        noEnrichmentDays: Array<string | null | undefined>;
        maxCapacity?: number | null | undefined;
        startDate?: string | null | undefined;
        endDate?: string | null | undefined;
        classesCount: number;
        courseImage?: string | null | undefined;
        courseCardImage?: string | null | undefined;
        closed: boolean;
        waitlistOpen: boolean;
        hasCheckoutOptions: boolean;
        searchable: boolean;
        site: { id: string; grades?: Array<{ value: string; label: string }> | null | undefined; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
        options: Array<
          | {
              id: string;
              checkout: boolean;
              description?: string | null | undefined;
              kind: Types.CourseOptionKind;
              required: boolean;
              title: string;
              choices: Array<{
                description?: string | null | undefined;
                id: string;
                name?: string | null | undefined;
                status: Types.CourseStatus;
                kind?: Types.CourseKind | null | undefined;
                grades?: Array<string | null | undefined> | null | undefined;
                ageMin?: number | null | undefined;
                ageMax?: number | null | undefined;
                noEnrichmentDays: Array<string | null | undefined>;
                maxCapacity?: number | null | undefined;
                startDate?: string | null | undefined;
                endDate?: string | null | undefined;
                classesCount: number;
                courseImage?: string | null | undefined;
                courseCardImage?: string | null | undefined;
                closed: boolean;
                waitlistOpen: boolean;
                hasCheckoutOptions: boolean;
                searchable: boolean;
                childVariantCourses: Array<{
                  id: string;
                  name?: string | null | undefined;
                  status: Types.CourseStatus;
                  kind?: Types.CourseKind | null | undefined;
                  grades?: Array<string | null | undefined> | null | undefined;
                  ageMin?: number | null | undefined;
                  ageMax?: number | null | undefined;
                  noEnrichmentDays: Array<string | null | undefined>;
                  maxCapacity?: number | null | undefined;
                  startDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  classesCount: number;
                  courseImage?: string | null | undefined;
                  courseCardImage?: string | null | undefined;
                  closed: boolean;
                  waitlistOpen: boolean;
                  hasCheckoutOptions: boolean;
                  searchable: boolean;
                  courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
                  company?: { id: string; name?: string | null | undefined } | null | undefined;
                  courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
                  prices: {
                    season: { days?: number | null | undefined; price?: number | null | undefined };
                    seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
                    recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
                    dropIn: { price?: number | null | undefined };
                    usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
                  };
                  teacher?: { name: string } | null | undefined;
                  site: { id: string; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
                }>;
                courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
                company?: { id: string; name?: string | null | undefined } | null | undefined;
                courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
                prices: {
                  season: { days?: number | null | undefined; price?: number | null | undefined };
                  seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
                  recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
                  dropIn: { price?: number | null | undefined };
                  usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
                };
                teacher?: { name: string } | null | undefined;
                site: { id: string; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
              }>;
            }
          | null
          | undefined
        >;
        courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
        company?: { id: string; name?: string | null | undefined } | null | undefined;
        courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
        prices: {
          season: { days?: number | null | undefined; price?: number | null | undefined };
          seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
          recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
          dropIn: { price?: number | null | undefined };
          usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
        };
        teacher?: { name: string } | null | undefined;
      }
    | null
    | undefined;
};

export const CourseOptionsDocument = gql`
  query CourseOptions($id: ID!) {
    course(course: $id) {
      site {
        grades {
          value
          label
        }
      }
      ...courseCardFields
      options {
        id
        checkout
        choices {
          ...courseCardFields
          childVariantCourses {
            ...courseCardFields
          }
          description
        }
        description
        kind
        required
        title
      }
    }
  }
  ${CourseCardFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CourseOptionsArgs = MakeOptional<UseQueryArgs<CourseOptionsQueryVariables, CourseOptionsQuery>, 'query'>;

export function useCourseOptionsQuery(options: CourseOptionsArgs = {}) {
  return useQuery<CourseOptionsQueryVariables, CourseOptionsQuery>({ query: CourseOptionsDocument, ...options });
}

export type CourseOptionsOptions = ExecQueryOptions<CourseOptionsQueryVariables>;

export function courseOptions(options: CourseOptionsOptions) {
  return executeQuery<CourseOptionsQuery, CourseOptionsQueryVariables>(CourseOptionsDocument, options);
}

export type CourseOptionsSelections = CourseOptionsQuery['course'];
