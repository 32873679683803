import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
import { EnrollmentFieldsFragmentDoc } from '../../gql/generated/enrollmentFields.generated';
import { EnrollmentInvitationFieldsFragmentDoc } from '../../gql/generated/enrollmentInvitationFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WaitlistedEnrollmentsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.RosterFilters>;
  sort?: Types.InputMaybe<Types.RosterSort>;
}>;

export type WaitlistedEnrollmentsQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              rosterPeriods?: string | null | undefined;
              status: Types.EnrollmentStatusEnum;
              id: string;
              createdAt: string;
              discountAmount: number;
              discountCodes: Array<string>;
              balance: number;
              pickup?: string | null | undefined;
              dismissal?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              isInvitation: boolean;
              invitationExpired?: boolean | null | undefined;
              course: {
                enrollmentOpens?: string | null | undefined;
                enrollmentCloses?: string | null | undefined;
                noEnrichmentDays: Array<string | null | undefined>;
                startDate?: string | null | undefined;
                endDate?: string | null | undefined;
                courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
              };
              priceConfig?:
                | { kind: Types.PriceConfigKind; dates: Array<string> }
                | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string>; startDate: string }
                | { kind: Types.PriceConfigKind; weekdays: Array<string> }
                | { kind: Types.PriceConfigKind; usageUnit: Types.UsageUnit }
                | null
                | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
              enrollmentPriceTierUse?: { cartPriceTierUse: { id: string; name: string } } | null | undefined;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    firstNameWithNickname: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    dob?: string | null | undefined;
                    nickname?: string | null | undefined;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const WaitlistedEnrollmentsDocument = gql`
  query WaitlistedEnrollments($groupingId: ID!, $filters: RosterFilters, $sort: RosterSort) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: COURSE, enrollmentStatus: WAITLISTED, filters: $filters, sort: $sort) {
      items {
        ...enrollmentFields
        ...enrollmentInvitationFields
        course {
          courseDays {
            days
            start
            finish
          }
          enrollmentOpens
          enrollmentCloses
          noEnrichmentDays
          startDate
          endDate
        }
        rosterPeriods
        priceConfig {
          ... on SeasonPriceConfig {
            kind
            weekdays
          }
          ... on RecurringPriceConfig {
            kind
            unit
            weekdays
            startDate
          }
          ... on DropInPriceConfig {
            kind
            dates
          }
          ... on UsagePriceConfig {
            kind
            usageUnit: unit
          }
        }
        status
      }
      total
    }
  }
  ${EnrollmentFieldsFragmentDoc}
  ${EnrollmentInvitationFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type WaitlistedEnrollmentsArgs = MakeOptional<UseQueryArgs<WaitlistedEnrollmentsQueryVariables, WaitlistedEnrollmentsQuery>, 'query'>;

export function useWaitlistedEnrollmentsQuery(options: WaitlistedEnrollmentsArgs = {}) {
  return useQuery<WaitlistedEnrollmentsQueryVariables, WaitlistedEnrollmentsQuery>({ query: WaitlistedEnrollmentsDocument, ...options });
}

export type WaitlistedEnrollmentsOptions = ExecQueryOptions<WaitlistedEnrollmentsQueryVariables>;

export function waitlistedEnrollments(options: WaitlistedEnrollmentsOptions) {
  return executeQuery<WaitlistedEnrollmentsQuery, WaitlistedEnrollmentsQueryVariables>(WaitlistedEnrollmentsDocument, options);
}

export type WaitlistedEnrollmentsSelections = WaitlistedEnrollmentsQuery['rosterByGrouping']['items'][0];

(WaitlistedEnrollmentsDocument as any).additionalTypenames = ['RosterItem', 'Enrollment'];
