import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentPriceBreakdownQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  discount?: Types.InputMaybe<Types.DiscountInput>;
  kind: Types.PriceConfigKind;
  recurring?: Types.InputMaybe<Types.RecurringPriceConfigInput>;
  dropIn?: Types.InputMaybe<Types.DropInPriceConfigInput>;
  configurableSeason?: Types.InputMaybe<Types.SeasonPriceConfigInput>;
  changingEnrollment?: Types.InputMaybe<Types.Scalars['ID']>;
  effective?: Types.InputMaybe<Types.ChangePricingPeriod>;
}>;

export type EnrollmentPriceBreakdownQuery = {
  enrollmentPriceBreakdown?:
    | {
        priceConfig:
          | { kind: Types.PriceConfigKind; template: boolean }
          | { unit: Types.RecurringUnit; weekdays: Array<string>; startDate: string; kind: Types.PriceConfigKind; template: boolean }
          | { weekdays: Array<string>; kind: Types.PriceConfigKind; template: boolean }
          | { kind: Types.PriceConfigKind; template: boolean; usageUnit: Types.UsageUnit };
        standard?: { amount?: number | null | undefined; discountAmount?: number | null | undefined; listPrice?: number | null | undefined } | null | undefined;
        first?:
          | {
              refund: number;
              charges: Array<
                | {
                    amount?: number | null | undefined;
                    date?: string | null | undefined;
                    discountAmount?: number | null | undefined;
                    listPrice?: number | null | undefined;
                    period?: string | null | undefined;
                    prorated?: boolean | null | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
        future?:
          | {
              charges: Array<
                | { amount?: number | null | undefined; date?: string | null | undefined; discountAmount?: number | null | undefined; listPrice?: number | null | undefined; prorated?: boolean | null | undefined }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const EnrollmentPriceBreakdownDocument = gql`
  query EnrollmentPriceBreakdown(
    $id: ID!
    $discount: DiscountInput
    $kind: PriceConfigKind!
    $recurring: RecurringPriceConfigInput
    $dropIn: DropInPriceConfigInput
    $configurableSeason: SeasonPriceConfigInput
    $changingEnrollment: ID
    $effective: ChangePricingPeriod
  ) {
    enrollmentPriceBreakdown(
      id: $id
      discount: $discount
      kind: $kind
      recurringPriceConfig: $recurring
      dropInPriceConfig: $dropIn
      configurableSeason: $configurableSeason
      changingEnrollment: $changingEnrollment
      effective: $effective
    ) {
      priceConfig {
        kind
        template
        ... on RecurringPriceConfig {
          unit
          weekdays
          startDate
        }
        ... on SeasonPriceConfig {
          weekdays
        }
        ... on UsagePriceConfig {
          usageUnit: unit
        }
      }
      standard {
        amount
        discountAmount
        listPrice
      }
      first {
        refund
        charges {
          amount
          date
          discountAmount
          listPrice
          period
          prorated
        }
      }
      future {
        charges {
          amount
          date
          discountAmount
          listPrice
          prorated
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EnrollmentPriceBreakdownArgs = MakeOptional<UseQueryArgs<EnrollmentPriceBreakdownQueryVariables, EnrollmentPriceBreakdownQuery>, 'query'>;

export function useEnrollmentPriceBreakdownQuery(options: EnrollmentPriceBreakdownArgs = {}) {
  return useQuery<EnrollmentPriceBreakdownQueryVariables, EnrollmentPriceBreakdownQuery>({ query: EnrollmentPriceBreakdownDocument, ...options });
}

export type EnrollmentPriceBreakdownOptions = ExecQueryOptions<EnrollmentPriceBreakdownQueryVariables>;

export function enrollmentPriceBreakdown(options: EnrollmentPriceBreakdownOptions) {
  return executeQuery<EnrollmentPriceBreakdownQuery, EnrollmentPriceBreakdownQueryVariables>(EnrollmentPriceBreakdownDocument, options);
}

export type EnrollmentPriceBreakdownSelections = EnrollmentPriceBreakdownQuery['enrollmentPriceBreakdown'];
