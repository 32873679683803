import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyTeamQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyTeamQuery = {
  company?:
    | {
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
        teamInvites?: Array<{ id: string; token: string; email: string; sentAt: string; role: Types.MemberRole } | null | undefined> | null | undefined;
        teamMembers?: Array<{ id: string; email: string; name: string; role: Types.MemberRole; isContact: boolean }> | null | undefined;
      }
    | null
    | undefined;
};

export const CompanyTeamDocument = gql`
  query CompanyTeam($company: ID!) {
    company(company: $company) {
      ...commonCompany
      teamInvites {
        id
        token
        email
        sentAt
        role
      }
      teamMembers {
        id
        email
        name
        role
        isContact
      }
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyTeamArgs = MakeOptional<UseQueryArgs<CompanyTeamQueryVariables, CompanyTeamQuery>, 'query'>;

export function useCompanyTeamQuery(options: CompanyTeamArgs = {}) {
  return useQuery<CompanyTeamQueryVariables, CompanyTeamQuery>({ query: CompanyTeamDocument, ...options });
}

export type CompanyTeamOptions = ExecQueryOptions<CompanyTeamQueryVariables>;

export function companyTeam(options: CompanyTeamOptions) {
  return executeQuery<CompanyTeamQuery, CompanyTeamQueryVariables>(CompanyTeamDocument, options);
}

export type CompanyTeamSelections = CompanyTeamQuery['company'];
