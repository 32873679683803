import { isEqual } from 'lodash-es';

// returns items in the updates array that are different than items in the
// originals array. only the specified properties are diffed.
//
// if no properties are specified then this will just return
// additions and deletions

// note this requires items in the array have an id property.

type ItemWithId = {id?:string};

export type ArrayDiff<T> = {
  added:Partial<T>[],
  removed:Partial<T>[],
  updated:Partial<T>[]
}

export function diffArrays<T extends ItemWithId>(originals:Partial<T>[], updates:Partial<T>[], properties:(keyof T)[] = null):ArrayDiff<T> {
  const originalsMap:Map<string, Partial<T>> = new Map();
  const updatesMap:Map<string, Partial<T>> = new Map();

  originals.forEach(o => originalsMap.set(o.id, o));
  updates.forEach(u => updatesMap.set(u.id, u));

  const added:Partial<T>[] = [];
  const removed:Partial<T>[] = [];
  const updated:Partial<T>[] = [];

  originals.forEach(o => {
    const u = updatesMap.get(o.id);
    if (!u) {
      removed.push(o);
    }
    else {
      if (properties) {
        for (const prop of properties) {
          if (o[prop] != u[prop]) {
            updated.push(o);
            break;
          }
        }
      }
      else 
      if (!isEqual(o, u)) {
        updated.push(o);
      }
    }
  });

  updates.forEach(u => {
    const o = originalsMap.get(u.id);
    if (!o) {
      added.push(u);
    }
  });

  return {added, removed, updated};
}

export function arrayUpdates<T extends ItemWithId>(originals:Partial<T>[], updates:Partial<T>[], properties:(keyof T)[] = []) {
  return diffArrays(originals, updates, properties).updated
}

export function arrayHasUpdates<T extends ItemWithId>(originals:Partial<T>[], updates:Partial<T>[], properties:(keyof T)[] = []) {
  return arrayUpdates(originals, updates, properties).length != 0;
}

export function arrayAdditions<T extends ItemWithId>(originals:Partial<T>[], updates:Partial<T>[], properties:(keyof T)[] = []) {
  return diffArrays(originals, updates, properties).added;
}

export function arrayHasAdditions<T extends ItemWithId>(originals:Partial<T>[], updates:Partial<T>[], properties:(keyof T)[] = []) {
  return arrayAdditions(originals, updates, properties).length != 0;
}

export function arrayRemovals<T extends ItemWithId>(originals:Partial<T>[], updates:Partial<T>[], properties:(keyof T)[] = []) {
  return diffArrays(originals, updates, properties).removed;
}

export function arrayHasRemovals<T extends ItemWithId>(originals:Partial<T>[], updates:Partial<T>[], properties:(keyof T)[] = []) {
  return arrayRemovals(originals, updates, properties).length != 0;
}
