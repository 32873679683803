import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentDetailFieldsFragmentDoc } from './studentDetailsFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type StudentDetailsQuery = {
  student?:
    | {
        id: string;
        name: string;
        firstName: string;
        lastName: string;
        nickname?: string | null | undefined;
        dob?: string | null | undefined;
        grade: string;
        automaticGradeUpdate: boolean;
        notes?: string | null | undefined;
        parents: Array<{ attendanceCodeCode?: string | null | undefined; id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string }>;
        emergencyContacts: Array<{
          attendanceCodeCode?: string | null | undefined;
          id: string;
          name?: string | null | undefined;
          relation?: string | null | undefined;
          phone?: string | null | undefined;
          email?: string | null | undefined;
        }>;
        authorizedPickupContacts: Array<
          | { attendanceCodeCode?: string | null | undefined; id: string; name?: string | null | undefined; relation?: string | null | undefined; phone?: string | null | undefined; email?: string | null | undefined }
          | null
          | undefined
        >;
        site?: { id: string; name?: string | null | undefined } | null | undefined;
        classroom?: { id: string; name: string; grade: string } | null | undefined;
        medical?:
          | {
              epiPen?: boolean | null | undefined;
              conditions?: string | null | undefined;
              allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
              medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const StudentDetailsDocument = gql`
  query StudentDetails($id: ID!) {
    student(student: $id) {
      ...studentDetailFields
    }
  }
  ${StudentDetailFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentDetailsArgs = MakeOptional<UseQueryArgs<StudentDetailsQueryVariables, StudentDetailsQuery>, 'query'>;

export function useStudentDetailsQuery(options: StudentDetailsArgs = {}) {
  return useQuery<StudentDetailsQueryVariables, StudentDetailsQuery>({ query: StudentDetailsDocument, ...options });
}

export type StudentDetailsOptions = ExecQueryOptions<StudentDetailsQueryVariables>;

export function studentDetails(options: StudentDetailsOptions) {
  return executeQuery<StudentDetailsQuery, StudentDetailsQueryVariables>(StudentDetailsDocument, options);
}

export type StudentDetailsSelections = StudentDetailsQuery['student'];
