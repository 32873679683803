import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteTeamQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SiteTeamQuery = { site?: { id: string; teamMembers?: Array<{ id: string; email: string; name: string; role: Types.MemberRole }> | null | undefined } | null | undefined };

export const SiteTeamDocument = gql`
  query SiteTeam($id: ID!) {
    site(site: $id) {
      id
      teamMembers {
        id
        email
        name
        role
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteTeamArgs = MakeOptional<UseQueryArgs<SiteTeamQueryVariables, SiteTeamQuery>, 'query'>;

export function useSiteTeamQuery(options: SiteTeamArgs = {}) {
  return useQuery<SiteTeamQueryVariables, SiteTeamQuery>({ query: SiteTeamDocument, ...options });
}

export type SiteTeamOptions = ExecQueryOptions<SiteTeamQueryVariables>;

export function siteTeam(options: SiteTeamOptions) {
  return executeQuery<SiteTeamQuery, SiteTeamQueryVariables>(SiteTeamDocument, options);
}

export type SiteTeamSelections = SiteTeamQuery['site'];
