import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
import { EnrollmentFieldsFragmentDoc } from '../../../gql/generated/enrollmentFields.generated';
import { EnrollmentInvitationFieldsFragmentDoc } from '../../../gql/generated/enrollmentInvitationFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DistinctEnrollmentsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.RosterFilters>;
  sort?: Types.InputMaybe<Types.RosterSort>;
}>;

export type DistinctEnrollmentsQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              listPrice: number;
              discountedPrice: number;
              startDate?: string | null | undefined;
              billingStartDate?: string | null | undefined;
              added?: string | null | undefined;
              lateStart: boolean;
              rosterPeriods?: string | null | undefined;
              paymentStatus: Types.PaymentStatus;
              overlaps?: boolean | null | undefined;
              isScholarshipHold: boolean;
              perSessionRefunds: boolean;
              refundsTotal: number;
              cfAnswers?: { [key: string]: any } | null | undefined;
              cfSeasonAnswers?: { [key: string]: any } | null | undefined;
              id: string;
              createdAt: string;
              discountAmount: number;
              discountCodes: Array<string>;
              balance: number;
              pickup?: string | null | undefined;
              dismissal?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              isInvitation: boolean;
              invitationExpired?: boolean | null | undefined;
              discountUses: Array<{ format?: Types.DiscountFormat | null | undefined; rate?: number | null | undefined }>;
              course: { courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined> };
              pendingChange?:
                | {
                    listPrice: number;
                    discountedPrice?: number | null | undefined;
                    startDate?: string | null | undefined;
                    priceConfig?: { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string> } | null | undefined;
                  }
                | null
                | undefined;
              priceConfigs?:
                | Array<
                    | { kind: Types.PriceConfigKind }
                    | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string>; updateDescription?: string | null | undefined }
                    | { kind: Types.PriceConfigKind; weekdays: Array<string>; updateDescription?: string | null | undefined }
                    | { kind: Types.PriceConfigKind }
                  >
                | null
                | undefined;
              contacts?: Array<{ name?: string | null | undefined; email?: string | null | undefined } | null | undefined> | null | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
              enrollmentPriceTierUse?: { cartPriceTierUse: { id: string; name: string } } | null | undefined;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    firstNameWithNickname: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    dob?: string | null | undefined;
                    nickname?: string | null | undefined;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const DistinctEnrollmentsDocument = gql`
  query DistinctEnrollments($groupingId: ID!, $filters: RosterFilters, $sort: RosterSort) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: COURSE, enrollmentStatus: ROSTERED_AND_INVITED, filters: $filters, sort: $sort) {
      total
      items {
        ...enrollmentFields
        ...enrollmentInvitationFields
        listPrice
        discountedPrice
        discountUses {
          format
          rate
        }
        course {
          courseDays {
            days
            start
            finish
          }
        }
        startDate
        billingStartDate
        added
        lateStart
        pendingChange {
          listPrice
          discountedPrice
          startDate
          priceConfig {
            ... on RecurringPriceConfig {
              kind
              unit
              weekdays
            }
          }
        }
        priceConfigs {
          ... on SeasonPriceConfig {
            kind
            weekdays
            updateDescription
          }
          ... on RecurringPriceConfig {
            kind
            unit
            weekdays
            updateDescription
          }
          ... on DropInPriceConfig {
            kind
          }
          ... on UsagePriceConfig {
            kind
          }
        }
        rosterPeriods
        paymentStatus
        overlaps
        isScholarshipHold
        perSessionRefunds
        refundsTotal
        contacts {
          name
          email
        }
        cfAnswers
        cfSeasonAnswers
      }
    }
  }
  ${EnrollmentFieldsFragmentDoc}
  ${EnrollmentInvitationFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type DistinctEnrollmentsArgs = MakeOptional<UseQueryArgs<DistinctEnrollmentsQueryVariables, DistinctEnrollmentsQuery>, 'query'>;

export function useDistinctEnrollmentsQuery(options: DistinctEnrollmentsArgs = {}) {
  return useQuery<DistinctEnrollmentsQueryVariables, DistinctEnrollmentsQuery>({ query: DistinctEnrollmentsDocument, ...options });
}

export type DistinctEnrollmentsOptions = ExecQueryOptions<DistinctEnrollmentsQueryVariables>;

export function distinctEnrollments(options: DistinctEnrollmentsOptions) {
  return executeQuery<DistinctEnrollmentsQuery, DistinctEnrollmentsQueryVariables>(DistinctEnrollmentsDocument, options);
}

export type DistinctEnrollmentsSelections = DistinctEnrollmentsQuery['rosterByGrouping']['items'][0];

(DistinctEnrollmentsDocument as any).additionalTypenames = ['RosterItem', 'Enrollment'];
