import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { CourseCardFieldsFragmentDoc } from '../../../../shared-public/course/course-card/generated/coursesCardFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PublicSiteQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  private?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type PublicSiteQuery = {
  site?:
    | {
        id: string;
        name?: string | null | undefined;
        active?: boolean | null | undefined;
        slug: string;
        streetAddress?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zipcode?: string | null | undefined;
        timezone?: string | null | undefined;
        usingAch: boolean;
        usingParentAttendance: boolean;
        partner: boolean;
        grades?: Array<{ value: string; label: string }> | null | undefined;
        siteCompanies: Array<{ id: string }>;
        recentSeasons: Array<{
          id: string;
          name: string;
          note?: string | null | undefined;
          approvedCourseKindGroups: Array<Types.CourseKindGroup>;
          coursesBegin: string;
          coursesFinish: string;
          enrollmentOpens: string;
          enrollmentCloses: string;
          holidays: Array<string | null | undefined>;
          registrationGroup?: string | null | undefined;
          courses: Array<{
            id: string;
            name?: string | null | undefined;
            status: Types.CourseStatus;
            kind?: Types.CourseKind | null | undefined;
            grades?: Array<string | null | undefined> | null | undefined;
            ageMin?: number | null | undefined;
            ageMax?: number | null | undefined;
            noEnrichmentDays: Array<string | null | undefined>;
            maxCapacity?: number | null | undefined;
            startDate?: string | null | undefined;
            endDate?: string | null | undefined;
            classesCount: number;
            courseImage?: string | null | undefined;
            courseCardImage?: string | null | undefined;
            closed: boolean;
            waitlistOpen: boolean;
            hasCheckoutOptions: boolean;
            searchable: boolean;
            childVariantCourses: Array<{
              id: string;
              name?: string | null | undefined;
              status: Types.CourseStatus;
              kind?: Types.CourseKind | null | undefined;
              grades?: Array<string | null | undefined> | null | undefined;
              ageMin?: number | null | undefined;
              ageMax?: number | null | undefined;
              noEnrichmentDays: Array<string | null | undefined>;
              maxCapacity?: number | null | undefined;
              startDate?: string | null | undefined;
              endDate?: string | null | undefined;
              classesCount: number;
              courseImage?: string | null | undefined;
              courseCardImage?: string | null | undefined;
              closed: boolean;
              waitlistOpen: boolean;
              hasCheckoutOptions: boolean;
              searchable: boolean;
              courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
              company?: { id: string; name?: string | null | undefined } | null | undefined;
              courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
              prices: {
                season: { days?: number | null | undefined; price?: number | null | undefined };
                seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
                recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
                dropIn: { price?: number | null | undefined };
                usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
              };
              teacher?: { name: string } | null | undefined;
              site: { id: string; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
            }>;
            courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
            company?: { id: string; name?: string | null | undefined } | null | undefined;
            courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
            prices: {
              season: { days?: number | null | undefined; price?: number | null | undefined };
              seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
              recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
              dropIn: { price?: number | null | undefined };
              usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
            };
            teacher?: { name: string } | null | undefined;
            site: { id: string; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
          }>;
        }>;
      }
    | null
    | undefined;
};

export const PublicSiteDocument = gql`
  query PublicSite($id: ID!, $private: Boolean) {
    site(site: $id) {
      id
      name
      active
      slug
      streetAddress
      city
      state
      zipcode
      timezone
      usingAch
      usingParentAttendance
      partner
      grades {
        value
        label
      }
      siteCompanies {
        id
      }
      recentSeasons(private: $private) {
        id
        name
        note
        approvedCourseKindGroups
        coursesBegin
        coursesFinish
        enrollmentOpens
        enrollmentCloses
        holidays
        registrationGroup
        courses {
          ...courseCardFields
          childVariantCourses {
            ...courseCardFields
          }
        }
      }
    }
  }
  ${CourseCardFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type PublicSiteArgs = MakeOptional<UseQueryArgs<PublicSiteQueryVariables, PublicSiteQuery>, 'query'>;

export function usePublicSiteQuery(options: PublicSiteArgs = {}) {
  return useQuery<PublicSiteQueryVariables, PublicSiteQuery>({ query: PublicSiteDocument, ...options });
}

export type PublicSiteOptions = ExecQueryOptions<PublicSiteQueryVariables>;

export function publicSite(options: PublicSiteOptions) {
  return executeQuery<PublicSiteQuery, PublicSiteQueryVariables>(PublicSiteDocument, options);
}

export type PublicSiteSelections = PublicSiteQuery['site'];
