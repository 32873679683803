import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompanyTeamQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminCompanyTeamQuery = { company?: { id: string; teamMembers?: Array<{ id: string; email: string; name: string; role: Types.MemberRole }> | null | undefined } | null | undefined };

export const AdminCompanyTeamDocument = gql`
  query AdminCompanyTeam($id: ID!) {
    company(company: $id) {
      id
      teamMembers {
        id
        email
        name
        role
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCompanyTeamArgs = MakeOptional<UseQueryArgs<AdminCompanyTeamQueryVariables, AdminCompanyTeamQuery>, 'query'>;

export function useAdminCompanyTeamQuery(options: AdminCompanyTeamArgs = {}) {
  return useQuery<AdminCompanyTeamQueryVariables, AdminCompanyTeamQuery>({ query: AdminCompanyTeamDocument, ...options });
}

export type AdminCompanyTeamOptions = ExecQueryOptions<AdminCompanyTeamQueryVariables>;

export function adminCompanyTeam(options: AdminCompanyTeamOptions) {
  return executeQuery<AdminCompanyTeamQuery, AdminCompanyTeamQueryVariables>(AdminCompanyTeamDocument, options);
}

export type AdminCompanyTeamSelections = AdminCompanyTeamQuery['company'];
