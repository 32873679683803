import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CoursePickerQueryVariables = Types.Exact<{
  seasonId?: Types.InputMaybe<Types.Scalars['ID']>;
  companyId?: Types.InputMaybe<Types.Scalars['ID']>;
  courseId?: Types.InputMaybe<Types.Scalars['ID']>;
  statuses: Array<Types.CourseStatus> | Types.CourseStatus;
  kinds?: Types.InputMaybe<Array<Types.CourseKind> | Types.CourseKind>;
}>;

export type CoursePickerQuery = {
  seasonCourses?:
    | {
        items: Array<
          | {
              id: string;
              name?: string | null | undefined;
              disambiguatedName?: string | null | undefined;
              description?: string | null | undefined;
              kind?: Types.CourseKind | null | undefined;
              status: Types.CourseStatus;
              parentVariantCourse?: { id: string } | null | undefined;
              rates: {
                season: { rate?: number | null | undefined };
                dropIn: { rate?: number | null | undefined };
                recurring: Array<{ rate?: number | null | undefined }>;
                usage: Array<{ rate?: number | null | undefined }>;
              };
              vendor?:
                | { id: string; displayName: string; email?: string | null | undefined; typename: 'Vendor'; company?: { id: string; userCompanyRole?: Types.MemberRole | null | undefined } | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const CoursePickerDocument = gql`
  query CoursePicker($seasonId: ID, $companyId: ID, $courseId: ID, $statuses: [CourseStatus!]!, $kinds: [CourseKind!]) @urql(additionalTypenames: ["Course"]) {
    seasonCourses(seasonId: $seasonId, companyId: $companyId, courseId: $courseId, statuses: $statuses, kinds: $kinds, sort: { by: NAME, direction: ASC }) {
      items {
        id
        name
        disambiguatedName
        description
        kind
        parentVariantCourse {
          id
        }
        status
        rates {
          season {
            rate
          }
          dropIn {
            rate
          }
          recurring {
            rate
          }
          usage {
            rate
          }
        }
        vendor {
          typename: __typename
          id
          displayName
          email
          company {
            id
            userCompanyRole
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CoursePickerArgs = MakeOptional<UseQueryArgs<CoursePickerQueryVariables, CoursePickerQuery>, 'query'>;

export function useCoursePickerQuery(options: CoursePickerArgs = {}) {
  return useQuery<CoursePickerQueryVariables, CoursePickerQuery>({ query: CoursePickerDocument, ...options });
}

export type CoursePickerOptions = ExecQueryOptions<CoursePickerQueryVariables>;

export function coursePicker(options: CoursePickerOptions) {
  return executeQuery<CoursePickerQuery, CoursePickerQueryVariables>(CoursePickerDocument, options);
}

export type CoursePickerSelections = CoursePickerQuery['seasonCourses']['items'][0];

(CoursePickerDocument as any).additionalTypenames = ['Course'];
