import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUserStudentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminUserStudentsQuery = { adminUser?: { students?: Array<{ id: string; name: string; parents: Array<{ id: string; name?: string | null | undefined }> }> | null | undefined } | null | undefined };

export const AdminUserStudentsDocument = gql`
  query AdminUserStudents($id: ID!) {
    adminUser(user: $id) {
      students {
        id
        name
        parents {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminUserStudentsArgs = MakeOptional<UseQueryArgs<AdminUserStudentsQueryVariables, AdminUserStudentsQuery>, 'query'>;

export function useAdminUserStudentsQuery(options: AdminUserStudentsArgs = {}) {
  return useQuery<AdminUserStudentsQueryVariables, AdminUserStudentsQuery>({ query: AdminUserStudentsDocument, ...options });
}

export type AdminUserStudentsOptions = ExecQueryOptions<AdminUserStudentsQueryVariables>;

export function adminUserStudents(options: AdminUserStudentsOptions) {
  return executeQuery<AdminUserStudentsQuery, AdminUserStudentsQueryVariables>(AdminUserStudentsDocument, options);
}

export type AdminUserStudentsSelections = AdminUserStudentsQuery['adminUser'];
