import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentStudentsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentStudentsQuery = {
  user?:
    | {
        students?:
          | Array<{
              id: string;
              firstName: string;
              lastName: string;
              name: string;
              nickname?: string | null | undefined;
              grade: string;
              dob?: string | null | undefined;
              formattedMedicalAndNotes: string;
              classroom?: { siteId: string } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ParentStudentsDocument = gql`
  query ParentStudents @urql(additionalTypenames: ["Student"]) {
    user: currentUser {
      students {
        id
        firstName
        lastName
        name
        nickname
        grade
        dob
        formattedMedicalAndNotes
        classroom {
          siteId
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentStudentsArgs = MakeOptional<UseQueryArgs<ParentStudentsQueryVariables, ParentStudentsQuery>, 'query'>;

export function useParentStudentsQuery(options: ParentStudentsArgs = {}) {
  return useQuery<ParentStudentsQueryVariables, ParentStudentsQuery>({ query: ParentStudentsDocument, ...options });
}

export type ParentStudentsOptions = ExecQueryOptions<ParentStudentsQueryVariables>;

export function parentStudents(options: ParentStudentsOptions) {
  return executeQuery<ParentStudentsQuery, ParentStudentsQueryVariables>(ParentStudentsDocument, options);
}

export type ParentStudentsSelections = ParentStudentsQuery['user'];

(ParentStudentsDocument as any).additionalTypenames = ['Student'];
